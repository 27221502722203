import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

import { Post } from "../.tina/__generated__/types";
import { useT } from "../src/translations";
import { displayDate, displayReadTime, getImageSrc } from "../src/utils";

import { BlurImgList } from "./BlurImg";
import { BoxSection } from "./Box";

const BoxPreview = styled(BoxSection)`
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min(calc(100% - 450px), 40%) auto;
  max-width: 100%;
  width: 100%;
  line-height: 0;

  &.noImage {
    grid-template-columns: 100%;
  }

  & .media {
    line-height: 0;
  }

  & .media .cropper {
    height: 290px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  & .media .cropped {
    margin: 0 -300px;
  }

  & .media img {
    max-height: 290px;
  }

  & .content {
    padding: 0 min(calc(var(--panel-padding) - 10px), 5%) 0
      min(var(--panel-padding), 5%);
    line-height: var(--line-height);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    max-height: 290px;
  }

  &.noImage .content {
    padding: var(--panel-padding);
  }

  @media screen and (max-width: 650px) {
    & {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
    }

    & .content {
      padding: var(--panel-padding);
      max-height: unset;
    }

    & .media .cropper {
      overflow: auto;
      height: auto;
    }

    & .media .cropped {
      margin: 0 0;
    }

    & .media img {
      max-width: 100%;
      max-height: unset;
    }
  }
`;

export function PostPreview({
  post,
  isFirst,
}: {
  post: Post;
  isFirst: boolean;
}) {
  const { locale } = useRouter();
  const t = useT();

  const { title, description, date, body, image, blur } = post;
  const { filename } = post._sys;

  const dateDisplay = displayDate(date, locale);
  const readtimeDisplay = displayReadTime(body, t);

  const hasImage = !!image;
  const { list: listUrl, list_webp: listWebpUrl } = hasImage
    ? getImageSrc(image)
    : { list: null, list_webp: null };

  let blurData;
  if (hasImage) {
    blurData = blur?.blur;
  }

  return (
    <BoxPreview className={!hasImage ? "noImage" : ""}>
      {hasImage && (
        <Link href={`/post/${filename}`} legacyBehavior={true}>
          <a className="media" tabIndex={-1}>
            <div className="cropper">
              <div className="cropped">
                <BlurImgList
                  srcJpg={listUrl}
                  srcWebp={listWebpUrl}
                  alt={title}
                  imgBlur={blurData}
                  aspectRatio="650 / 368"
                  width={650}
                  isFirst={isFirst}
                />
              </div>
            </div>
          </a>
        </Link>
      )}
      <div className="content">
        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
          <Link href={`/post/${filename}`}>{title}</Link>
        </h3>
        <div style={{ marginTop: 0, marginBottom: "10px" }}>
          <small>
            {dateDisplay} · {readtimeDisplay}
          </small>
        </div>
        <p style={{ margin: 0 }}>{description}</p>
      </div>
    </BoxPreview>
  );
}
