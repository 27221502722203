import type { BlogPosting } from "schema-dts";

import { Post } from "../../../.tina/__generated__/types";
import { getBlogPostingUrl } from "../../../src/getUrls";
import { getImageFullUrl, getImageSrc } from "../../../src/utils";

import { getAuthorJsonLd } from "./getAuthorJsonLd";
import { getBlogJsonLd } from "./getBlogJsonLd";

export type GetBlogPostingJsonLdParams = {
  locale: string;
  post: Pick<Post, "title" | "description" | "date" | "image">;
  slug: string;
  nestedProperties: boolean;
};

export function getBlogPostingJsonLd({
  locale,
  post,
  slug,
  nestedProperties,
}: GetBlogPostingJsonLdParams): BlogPosting {
  const { og: ogUrl } = getImageSrc(post.image);

  const base = {
    "@type": "BlogPosting",
    "@id": getBlogPostingUrl({ locale, slug }),
    name: post.title,
    headline: post.title,
    description: post.description,
    datePublished: post.date,
    image: getImageFullUrl(ogUrl),
    author: getAuthorJsonLd({ locale }),
  } as const;
  if (!nestedProperties) {
    return base;
  }
  const nested = {
    isPartOf: getBlogJsonLd({ nestedProperties: false, locale }),
  };
  return {
    ...base,
    ...(nestedProperties ? nested : {}),
  };
}
