import { ORIGIN } from "./utils";

const getOriginWithLocale = (locale: string) => {
  return `${ORIGIN}${locale === "fr" ? "/fr" : ""}`;
};

type GetBlogUrlParams = {
  locale: string;
};
export const getBlogUrl = ({ locale }: GetBlogUrlParams) => {
  return getOriginWithLocale(locale);
};

type GetBlogPostingUrlParams = {
  locale: string;
  slug: string;
};

export const getBlogPostingUrl = ({
  locale,
  slug,
}: GetBlogPostingUrlParams) => {
  return `${getOriginWithLocale(locale)}/post/${slug}`;
};
