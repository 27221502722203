import { useRouter } from "next/router";
import styled from "styled-components";
import { staticRequest } from "tinacms";
import { useTina } from "tinacms/dist/react";
import { TinaMarkdown } from "tinacms/dist/rich-text";

import {
  I18n_FrConnection,
  Page,
  PostConnection,
} from "../.tina/__generated__/types";
import { BoxSection } from "../components/Box";
import { PostPreview } from "../components/PostPreview";
import { Layout } from "../components/layout/Layout";
import { getBlogJsonLd } from "../components/layout/head/getBlogJsonLd";
import { getTranslatedPosts } from "../src/getTranslatedPosts";
import { getListGql } from "../src/gql/getGql";
import { getSortedListablePosts } from "../src/utils";

/* eslint-disable prettier/prettier */

const getQuery = (locale: string, isLive?: boolean) => ({
  query: `
  query getIndex($relativePath: String!) {
    page(relativePath: $relativePath) {
      body
    }
    ${getListGql(isLive)}
  }`,
  variables: {
    relativePath: `${locale === "fr" ? "fr/" : ""}home.mdx`,
  },
});

/* eslint-enable prettier/prettier */

const StyledH2 = styled.h2`
  color: white;
`;

type DataType = {
  page: Page;
  postConnection: PostConnection;
  i18n_frConnection: I18n_FrConnection;
};

export default function IndexPage(props: { data: DataType; query: string }) {
  // Pass our data through the "useTina" hook to make it editable
  const { data } = useTina({
    query: props.query,
    variables: {},
    data: props.data,
  });
  const { locale } = useRouter();

  const page = data.page.body;

  const enEdges = data.postConnection.edges;
  const frEdges = data.i18n_frConnection?.edges;

  const translatedPosts = getTranslatedPosts(enEdges, frEdges, locale);
  const sortedPosts = getSortedListablePosts(translatedPosts);

  const jsonLd = getBlogJsonLd({
    locale,
    blogPosts: sortedPosts,
    nestedProperties: true,
  });

  return (
    <Layout title="Protectator" jsonLd={jsonLd}>
      <article>
        <BoxSection>
          <TinaMarkdown content={page} />
        </BoxSection>
      </article>
      <header>
        <StyledH2>Posts</StyledH2>
      </header>
      <div>
        {sortedPosts.map((post, i) => (
          <PostPreview post={post} key={post.id} isFirst={i === 0} />
        ))}
      </div>
    </Layout>
  );
}

export const getStaticProps = async (ctx) => {
  const query = getQuery(ctx.locale, false);
  const data = await staticRequest({ ...query });

  return {
    props: {
      query: getQuery(ctx.locale, true),
      variables: {},
      data,
    },
  };
};
