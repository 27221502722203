import styled from "styled-components";

export type BlurImgProps = {
  srcJpg: string;
  srcWebp: string;
  alt?: string;
  imgBlur: string;
  aspectRatio: string;
};

export type BlurImgListProps = {
  srcJpg: string;
  srcWebp: string;
  alt?: string;
  width: number;
  imgBlur: string;
  aspectRatio: string;
  isFirst?: boolean;
};

const BlurredImg = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  filter: blur(min(5vw, 20px));
`;

export function BlurImg({
  srcJpg,
  srcWebp,
  alt,
  imgBlur,
  aspectRatio,
}: BlurImgProps) {
  return (
    <div style={{ position: "relative", lineHeight: 0, overflow: "hidden" }}>
      <BlurredImg
        src={imgBlur}
        style={{
          aspectRatio: aspectRatio,
        }}
        alt=""
        aria-hidden="true"
      />
      <picture>
        <source srcSet={srcWebp} type="image/webp" />
        <source srcSet={srcJpg} type="image/jpeg" />
        <img
          src={srcJpg}
          alt={alt}
          style={{ position: "absolute", left: 0, top: 0 }}
        />
      </picture>
    </div>
  );
}

const StyledBlurListImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const BlurredImgList = styled.img`
  position: relative;
  height: auto;
  filter: blur(min(5vw, 20px));
`;

const BlurredImgDiv = styled.div`
  position: relative;
  line-height: 0;
`;

export function BlurImgList({
  srcJpg,
  srcWebp,
  alt,
  imgBlur,
  aspectRatio,
  width,
  isFirst,
}: BlurImgListProps) {
  return (
    <BlurredImgDiv>
      <BlurredImgList
        src={imgBlur}
        style={{
          width: width,
          aspectRatio: aspectRatio,
        }}
        alt=""
        aria-hidden="true"
      />
      <picture>
        <source srcSet={srcWebp} type="image/webp" />
        <source srcSet={srcJpg} type="image/jpeg" />
        <StyledBlurListImg
          src={srcJpg}
          alt={alt}
          loading={isFirst ? "eager" : "lazy"}
        />
      </picture>
    </BlurredImgDiv>
  );
}
