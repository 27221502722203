import {
  I18n_FrConnectionEdges,
  Post,
  PostConnectionEdges,
} from "../.tina/__generated__/types";

export function getTranslatedPosts(
  edges: PostConnectionEdges[],
  frEdges: I18n_FrConnectionEdges[],
  locale: string
) {
  let translatedPosts = edges.map((e) => e.node);

  if (locale === "fr") {
    // Get data, index it by filename
    const frPosts = {};
    for (const { node } of frEdges) {
      frPosts[node._sys.filename] = node;
    }
    // Replace data
    translatedPosts = [];
    for (const post of edges) {
      const correspondingTranslation = frPosts[post.node._sys.filename];
      const frNode: Post = {
        ...post.node,
        ...correspondingTranslation,
      };
      translatedPosts.push(frNode);
    }
  }
  return translatedPosts;
}
