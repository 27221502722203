import type { Person } from "schema-dts";

import { blogAuthorName } from "./values";

export type GetBlogJsonLdParams = {
  locale: string;
};

export function getAuthorJsonLd({ locale }: GetBlogJsonLdParams): Person {
  return {
    "@type": "Person",
    name: blogAuthorName,
    url: "https://kewindousse.ch",
  };
}
