import type { Blog } from "schema-dts";

import { Post } from "../../../.tina/__generated__/types";
import { getBlogUrl } from "../../../src/getUrls";

import { getBlogPostingJsonLd } from "./getBlogPostingJsonLd";
import { blogDescription, blogDescriptionFr, blogTitle } from "./values";

export type GetBlogJsonLdParams = {
  locale: string;
  blogPosts?: Post[];
  nestedProperties: boolean;
};

export function getBlogJsonLd({
  locale,
  blogPosts,
  nestedProperties,
}: GetBlogJsonLdParams): Blog {
  const base = {
    "@type": "Blog",
    "@id": getBlogUrl({ locale }),
    name: blogTitle,
    headline: blogTitle,
    description: locale === "fr" ? blogDescriptionFr : blogDescription,
  } as const;
  if (!nestedProperties) {
    return base;
  }
  const nested = {
    blogPost: blogPosts.map((post) =>
      getBlogPostingJsonLd({
        post,
        locale,
        slug: post._sys.filename,
        nestedProperties: false,
      })
    ),
  };
  return {
    ...base,
    ...(nestedProperties ? nested : {}),
  };
}
